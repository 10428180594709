import { useState } from 'react';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { AlertContext } from './Alert.Context';

export default function AlertProvider({ children }: WithChildren) {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setAlertMessage] = useState<string | number>('');
    const [severity, setAlertSeverity] = useState<AlertColor>('success');
    const close = () => setOpen(false);

    const success = (msg?: string | number) => {
        setAlertSeverity('success');
        setAlertMessage(msg ?? 'Success!');
        setOpen(true);
    };

    const error = (msg?: string | number) => {
        setAlertSeverity('error');
        setAlertMessage(msg ?? 'Error!');
        setOpen(true);
    };
    const info = (msg?: string | number) => {
        setAlertSeverity('info');
        setAlertMessage(msg ?? 'Info!');
        setOpen(true);
    };

    const warning = (msg?: string | number) => {
        setAlertSeverity('warning');
        setAlertMessage(msg ?? 'Warning!');
        setOpen(true);
    };

    return (
        <AlertContext.Provider value={{ success, error, info, warning }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={close}
                action={
                    <IconButton
                        size='small'
                        aria-label='close'
                        color='inherit'
                        onClick={close}
                    >
                        <CloseIcon fontSize='small' />
                    </IconButton>
                }
                aria-labelledby={`${message}-alert-title`}
                aria-describedby={`${message}-alert-description`}
            >
                <MuiAlert
                    severity={severity}
                    variant='standard'
                    sx={{ width: '100%' }}
                    onClose={close}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </AlertContext.Provider>
    );
}
