import { ReactElement, memo, CSSProperties } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

type triggerTypes =
    | 'click'
    | 'hover'
    | 'loop'
    | 'loop-on-hover'
    | 'morph'
    | 'morph-two-way';

export const icons = {};

export interface LordIconProps {
    trigger?: triggerTypes;
    icon: keyof typeof icons;
    maxWidth?: string;
    minWidth?: string;
    primary?: string;
    secondary?: string;
    delay?: number;
    tooltip?: string;
    TooltipProps?: TooltipProps;
    style?: CSSProperties | undefined;
}

function LordIcon({
    trigger = 'loop',
    icon,
    maxWidth,
    minWidth,
    primary,
    secondary,
    style,
    tooltip,
    TooltipProps,
    ...props
}: LordIconProps): ReactElement {
    return tooltip ? (
        <Tooltip title={tooltip} arrow {...TooltipProps}>
            <lord-icon
                trigger={trigger}
                src={`https://cdn.lordicon.com/${icons[icon]}.json`}
                colors={`primary:${primary ?? 'inherit'},secondary:${
                    secondary ?? 'inherit'
                }`}
                style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: maxWidth ?? 'auto',
                    minWidth: minWidth ?? 'auto',
                    cursor: trigger === 'click' ? 'cursor' : 'default',
                    ...style
                }}
                id={icon}
                {...props}
            ></lord-icon>
        </Tooltip>
    ) : (
        <lord-icon
            trigger={trigger}
            src={`https://cdn.lordicon.com/${icons[icon]}.json`}
            colors={`primary:${primary ?? 'inherit'},secondary:${
                secondary ?? 'inherit'
            }`}
            style={{
                width: '100%',
                height: 'auto',
                maxWidth: maxWidth ?? 'auto',
                minWidth: minWidth ?? 'auto',
                cursor: trigger === 'click' ? 'cursor' : 'default',
                ...style
            }}
            id={icon}
            {...props}
        ></lord-icon>
    );
}

export default memo(LordIcon);
