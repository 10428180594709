import { DefaultTheme } from 'styled-components';
import routes, {
    routeTypes,
    publicRoutes,
    INavigate,
    WithLink
} from './routes.config';
//! LIVE
import { routeConfig, console_log } from './live.config';
//? DEV
// import { routeConfig, console_log } from './dev.config';
const radius = {
    default: '1rem',
    small: '5px',
    medium: '10px',
    large: '2rem'
};

const dateFormats = {
    short: 'D MMM HH:mm',
    full: 'DD/MM/YY HH:mm',
    call: 'YYYY-MM-DD HH:mm:ss',
    normal: 'DD/MM/YYYY'
};

export const Theme: DefaultTheme = {
    borderRadius: {
        default: '1rem',
        small: '5px',
        medium: '10px',
        large: '2rem'
    },
    background: '#ccc',
    primary: {
        base: '#7b90a3',
        accent: '#97bec1',
        light: '#2A57FC',
        dark: '#0023A2',
        darker: '#001C7F'
    },
    secondary: {
        base: '#a7c9cf',
        accent: '#03CFFF',
        light: '#14CFFB',
        dark: '#007793',
        darker: '#005E74'
    },
    secondary2: {
        base: '#00DD3A',
        accent: '#00FF43',
        light: '#0EFD4C',
        dark: '#00B22F',
        darker: '#008C25'
    },
    text: '#262626'
};

const config = {
    routeConfig,
    console_log,
    radius,
    dateFormats
};

export {
    config as default,
    routeConfig,
    console_log,
    radius,
    publicRoutes,
    dateFormats,
    routes
};
export type { routeTypes, INavigate, WithLink };
