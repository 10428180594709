import AssessmentsIcon from "../assets/images/services/check-list.png";
import ChildrenIcon from "../assets/images/services/children.png";
import AdultIcon from "../assets/images/services/couple.png";
import FamilyIcon from "../assets/images/services/family.png";
import CoupleIcon from "../assets/images/services/in-love.png";
import OnlineIcon from "../assets/images/services/laptop.png";
import GroupIcon from "../assets/images/services/people.png";
import IndividualIcon from "../assets/images/services/user.png";
import HorseIcon from "../assets/images/services/horse-head.png";

export interface ServiceProps {
  Icon: string;
  title: string;
  shortDescription: string;
  description: string;
  list?: string[];
}

export const ServicesConstant = {
  Adult: {
    Icon: AdultIcon,
    title: "Adult",
    shortDescription:
      "Caused by - but is not limited to – traumatic, relational, emotional, developmental, and lifestyle challenges that cause distress and discomfort for the person.",
    description:
      "Adults seek psychotherapy for various reasons. This can include – but is not limited to – traumatic, relational, emotional, developmental, and lifestyle challenges that cause distress and discomfort for the person. Individuals may also seek assistance with the effective integration of a life situation for better self-understanding and to foster the development of a healthier functional self.",
  },
  Child: {
    Icon: ChildrenIcon,
    title: "Child and Adolescent",
    shortDescription:
      "Psychotherapy with children and adolescents (ages 2 to 18) is based on the unique needs, interests, characteristics, and personality styles of the individuals.",
    description:
      "Play, like art, is often the way children communicate and express their emotions. Therefore, interventions can incorporate creative therapy – such as art, sand tray, puppets – or talking therapy – including exploration of emotions, thoughts, and experiences – or a combination of both. The caregivers are collaboratively involved with this therapeutic process in order to support the caregivers and children / adolescents to better understand one another and strengthen their relationship with each other.",
  },
  Individual: {
    Icon: IndividualIcon,
    title: "Individual",
    shortDescription:
      "Individual therapy involves the therapist and individual engaging in the exploration of various and pertinent aspects influencing their functioning.",
    description:
      "Individual therapy involves the therapist and individual engaging in the exploration of various and pertinent aspects influencing their functioning. Each therapeutic process is tailored to meet the challenges, functionality, and personality of the individual. Commitment to the therapeutic journey allows for change. The ultimate aim is to collaboratively work with the individual through an integrative approach to assist the person towards living a more fulfilled and authentic lifestyle.",
  },
  Couples: {
    Icon: CoupleIcon,
    title: "Couples",
    shortDescription:
      "There can be times when couples experience challenges that place strain on their relationship.",
    description:
      "There can be times when couples experience challenges that place strain on their relationship. IPA couples' therapy involves therapy sessions with each partner on their own before commencing with joint sessions whereby both individuals are present. Couples' therapy focuses on the exploration of dysfunctional patterns within the relationship to facilitate the development of better and more effective interactional communication styles within the couple.",
  },
  Family: {
    Icon: FamilyIcon,
    title: "Family",
    shortDescription:
      "Family therapy seeks to explore ineffective patterns of communication and challenges experiences within the family system.",
    description:
      "Family therapy seeks to explore ineffective patterns of communication and challenges experiences within the family system. Usually, the therapy involves the family as a unit being present for sessions. The therapeutic process aims to facilitate families in developing strategies to function in more optimal ways within their systems.",
  },
  Group: {
    Icon: GroupIcon,
    title: "Group",
    shortDescription:
      "A group of individuals sharing similar challenges can attend group therapy whereby all the people receive therapy at the same time by one (or two) therapist(s).",
    description:
      "A group of individuals sharing similar challenges can attend group therapy whereby all the people receive therapy at the same time by one (or two) therapist(s). Such therapy offers group members the opportunity to receive and provide support to others within a safe space. Additionally, the process facilitates the development of effective communication skills with other individuals. Group therapy can be utilised to provide psycho-education and skills training to a group of individuals. Trauma debriefing within the workspace would also benefit from such therapy.",
  },
  Online: {
    Icon: OnlineIcon,
    title: "Online",
    shortDescription:
      "Online therapy is the same as face-to-face therapy, but the therapist and client are not physically within the same space.",
    description:
      "Online therapy is the same as face-to-face therapy, but the therapist and client are not physically within the same space. Preference for online therapy consultations is available upon request if necessary.",
  },
  Assessments: {
    Icon: AssessmentsIcon,
    title: "Assessments",
    shortDescription: "Administering the following assessments upon request",
    list: [
      'Neurological Assessments',
      'Cognitive Assessments',
      'Caregiver-Child Assessments',
      'Personality Assessments',
      'Psycho-legal Assessments',
    ],
    description:
      "The most complete and most effective program I've ever written. You will never need another program again!",
  },
  Horse: {
    Icon: HorseIcon,
    title: "Equine Assisted Psychotherapy (EAP)",
    shortDescription: "Equine Assisted Psychotherapy (EAP) is a treatment modality that incorporates horses into the therapeutic process with individuals of all ages, families, and groups.",
    description:
      "Equine Assisted Psychotherapy (EAP) is a treatment modality that incorporates horses into the therapeutic process with individuals of all ages, families, and groups. EAP is an experiential evidence-based intervention for the treatment of a variety of psychological challenges such as trauma, stressor-related experiences, interactional style difficulties, etc. Often, EAP is employed as a complementary therapeutic modality along with other forms of treatment such as BrainSpotting, BWRT, and talk therapy. EAP affords individuals an opportunity to be outdoors and to utilise all of their senses during the process that unfolds within a non-judgemental therapeutic holding space.",
  },
};
