import { ReactElement, useCallback, memo } from 'react';
import useFetch from '../../hooks/useFetch';
import { Form } from '..';

export interface IContact {
    Name: string;
    Email: string;
    Phone?: string | undefined;
    Subject: string;
    Message: string;
}

const contactDetailsInitial: IContact = {
    Name: '',
    Email: '',
    Subject: '',
    Message: ''
};

function ContactForm(): ReactElement {
    const [post, loading] = useFetch('/contact');
    const submit = useCallback(
        async details =>
            await post({ data: details, message: 'Message sent!' }),
        [post]
    );
    return (
        <Form
            initialData={contactDetailsInitial}
            onSubmit={submit}
            loading={loading}
            maxWidth='md'
            submitLabel='Send Message'
            fieldVariant='standard'
            Fields={[
                { name: 'Name', sm: 6 },
                { name: 'Subject', sm: 6 },
                { name: 'Email', sm: 6 },
                { name: 'Phone', sm: 6, optional: true },
                { name: 'Message', multiline: true }
            ]}
        />
    );
}
export default memo(ContactForm);
