import {ReactElement, memo} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Action, Service} from "..";
import {ServiceProps} from "../../constants";

interface ServicesProps {
  home?: boolean;
  services: Array<ServiceProps>;
}

function Services({home, services}: ServicesProps): ReactElement {
  return (
    <Stack
      component="section"
      mt={home ? 5 : "10rem"}
      mb="5rem"
      minWidth="100%"
      maxWidth="100%"
      id="services"
      textAlign="center"
    >
      <Typography
        component="h3"
        variant="h3"
        fontWeight={600}
        mx={0}
        mb="0.5rem"
      >
        Psychotherapy and Assessments
      </Typography>
      <Typography variant="subtitle1" component="span" mb="4rem">
        Psychotherapy can be provided in English and / or Afrikaans
      </Typography>
      <Grid
        container
        rowSpacing={8}
        columnSpacing={6}
        columns={home ? 12 : 8}
        justifyContent="center"
        alignContent="stretch"
        alignItems="stretch"
        mb="2rem"
      >
        {services.map((service) => (
          <Service service={service}/>
        ))}
      </Grid>
      {home && (
        <Action av="Button" label="Find out more" to={{path: "/services"}}/>
      )}
    </Stack>
  );
}

export default memo(Services);
