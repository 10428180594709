import { createContext, useContext } from 'react';

export interface AlertContextProps {
    success: (msg?: string | number | undefined) => void;
    error: (msg?: string | number | undefined) => void;
    warning: (msg?: string | number | undefined) => void;
    info: (msg?: string | number | undefined) => void;
}

export const AlertContext = createContext<AlertContextProps>({
    success: () => {},
    error: () => {},
    warning: () => {},
    info: () => {}
});

export const useAlert = () => useContext(AlertContext);
