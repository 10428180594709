import { ReactElement, memo } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Action } from "..";
import Profile from "../../assets/images/Profile.jpeg";
import Box from "@mui/material/Box";

function About({ page }: { page?: boolean }): ReactElement {
  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      alignItems="center"
      justifyContent="space-between"
      mt="5rem"
      spacing={5}
    >
      <Stack
        component="section"
        alignItems="flex-start"
        maxWidth="100%"
        textAlign="left"
        id="about"
      >
        <Typography variant="h3" component="h3" fontWeight={600}>
          Hello, <br /> I am Deidré du Toit,
        </Typography>
        <Typography
          variant="subtitle1"
          component="caption"
          mt={2}
          textAlign="left"
        >
          a clinical psychologist located in Bloemfontein. I am passionate about
          working with people of all ages – children (from 2 years old),
          adolescents, adults, and families – to explore a range of emotional
          and behavioural concerns. I have a special interest in children,
          working with caregivers and their children, individual adult
          psychotherapy, and couples’ therapy.
        </Typography>
        {!page && (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            mt={4}
            width="100%"
            spacing={2}
          >
            <Action
              av="Button"
              size="large"
              color="secondary"
              label="Contact Me"
              sx={{ color: "white" }}
            />
            <Action
              av="Button"
              size="large"
              label="More about me"
              variant="outlined"
              color="primary"
              to={{ path: "/about" }}
            />
          </Stack>
        )}
      </Stack>
      <Box
        component="img"
        src={Profile}
        alt={Profile}
        minWidth="18rem"
        maxWidth="18rem"
        minHeight="18rem"
        maxHeight="18rem"
        borderRadius="50%"
        sx={{ objectFit: "cover", objectPosition: "center" }}
      />
    </Stack>
  );
}
export default memo(About);