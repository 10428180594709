import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: #111;
  position: relative;
  ::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    background: #111;
    border-radius: 5px;
    width: 0;
    transition: width 0.4s ease-in-out;
  }
  :hover {
    ::after {
      width: 100%;
    }
  }
  :active {
    ::after {
      width: 100%;
    }
  }
  :focus {
    ::after {
      width: 100%;
    }
  }
`;

export const NavAnchor = styled.a`
  text-decoration: none;
  font-size: 1.1rem;
  color: #111;
  font-weight: 600;

  position: relative;
  ::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    background: #111;
    border-radius: 5px;
    width: 0;
    transition: width 0.4s ease-in-out;
  }
  :hover {
    ::after {
      width: 100%;
    }
  }
  :active {
    ::after {
      width: 100%;
    }
  }
  :focus {
    ::after {
      width: 100%;
    }
  }
`;