import {ReactElement, memo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Action from "../Custom/Action";
import {routeTypes} from "../../config";

export interface FeeComponentProps {
  icon: string;
  label: string;
  value: string;
  to?: routeTypes;
  href?: string;
  action?: boolean;
}

function Fee({
               icon,
               label,
               value,
               to,
               href,
               action,
             }: FeeComponentProps): ReactElement {
  return (
    <Paper component="div" sx={{background: "#d8e8ea", height: "100%"}}>
      <Stack
        component="section"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
        p="1.5rem"
        height="100%"
      >
        <Stack maxWidth="5rem" maxHeight='5.5rem' mb="1rem" mt="-4rem">
          <img src={icon} alt={icon} style={{maxHeight: '5.5rem'}}/>
        </Stack>
        <Typography
          component="h5"
          variant="h5"
          fontWeight={500}
          textAlign="center"
          mb={2}
        >
          {label}
        </Typography>
        {action ? (
          <Action
            av="Button"
            label={
              <Typography
                component="sub"
                variant="caption"
                textAlign="center"
              >
                {value}
              </Typography>
            }
            color="inherit"
            variant="outlined"
            size="small"
            href={href}
            to={to && {path: to}}
            fullWidth={false}
          />
        ) : (
          <Typography component="sub" variant="caption" textAlign="center">
            {value}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
}

export default memo(Fee);
