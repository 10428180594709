import { ReactElement, memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Action from "../Custom/Action";

function PopiSection(): ReactElement {
  return (
    <Stack spacing={2} my="4rem">
      <Typography
        variant="h3"
        component="h3"
        fontWeight={600}
        sx={{ wordBreak: "break-word" }}
      >
        POPI Act
      </Typography>
      <Typography variant="subtitle1" component="h6" fontWeight={600}>
        I highly value the right to privacy and, in accordance with the
        Protection of Personal Information Act 4 of 2013, have therefore applied
        certain procedures within the practice to ascertain that the personal
        information of individuals is protected.
      </Typography>
      <Typography variant="subtitle1" component="h6" fontWeight={500}>
        Below are links to view my POPI Act manual and POPI forms are you
        require them.
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
        <Action
          av="Button"
          size="large"
          label="FORM 1: OBJECTION TO THE PROCESSING OF PERSONAL INFORMATION"
          href="/documents/Form-1.pdf"
          target="_blank"
          endIcon={<ArrowForwardIos />}
        />
        <Action
          av="Button"
          size="large"
          label="FORM 2: REQUEST FOR CORRECTION OR DELETION OF PERSONAL INFORMATION"
          href="/documents/Form-2.pdf"
          target="_blank"
          endIcon={<ArrowForwardIos />}
        />
        <Action
          av="Button"
          size="large"
          label="Procedures for Protection of Personal Information"
          href="/documents/Procedures.pdf"
          target="_blank"
          endIcon={<ArrowForwardIos />}
        />
      </Stack>
    </Stack>
  );
}

export default memo(PopiSection);