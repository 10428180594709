import { ReactElement, useMemo, memo, useRef, useEffect } from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import routes from './config/routes.config';
import useNavigate from './hooks/useNavigate';
import NotFound from './pages/NotFound.Page';

function Routes(): ReactElement {
    const { path } = useNavigate();
    const topRef = useRef<HTMLElement>(null);
    const memoizedRoutes = useMemo(() => routes, []);
    useEffect(() => topRef.current?.scrollIntoView(), [path]);
    return (
        <main ref={topRef}>
            <RouterRoutes>
                {memoizedRoutes.public.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                    />
                ))}

                <Route path='*' element={<NotFound />} />
            </RouterRoutes>
        </main>
    );
}

export default memo(Routes);
