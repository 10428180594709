import { ReactElement, memo } from 'react';
import Divider, { DividerProps } from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

function GridDivider({
    xs,
    sm,
    md,
    lg,
    ...dividerProps
}: WithGrid<DividerProps>): ReactElement {
    return (
        <Grid item xs={xs ? xs : 12} sm={sm} md={md} lg={lg}>
            <Divider variant='fullWidth' {...dividerProps} />
        </Grid>
    );
}

export default memo(GridDivider);
