export const Achievements = {
  Qualifications: [
    "Master of Arts in Clinical Psychology (with mini-dissertation distinction)",
    "Honours in Psychology (with distinction)",
    "Bachelor of Arts majoring in Psychology and Education (with distinction)",
  ],
  FurtherTraining: [
    "Interactional Pattern Analysis (IPA) - Level I, Level II, Level III",
    "Couples IPA",
    "Equine Assisted Psychotherapy (EAP)",
    "Brainspotting – Level I",
    "Brain Working Recursive Therapy (BWRT) – Level I, Level 2",
    "BWRT with children",
    "BWRT with adolescents",
    "Eye Movement Desensitization and Reprocessing (EMDR) – Level 1",
    "Offender risk assessment and profiling training (presenter: Prof. Gérard Labuschagne)",
    "Sand tray therapy",
    "Child play therapy",
    "Techniques that utilise Theraplay®",
  ],
};