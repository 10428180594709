import { ReactElement, memo } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Achievements } from '../../constants';

function Qualifications(): ReactElement {
    return (
        <Box
            component='section'
            width='100vw'
            mt='5rem'
            paddingY='2rem'
            bgcolor='#eee'
        >
            <Container component='main' maxWidth='lg'>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems='flex-start'
                    spacing={3}
                >
                    <Stack alignItems='flex-start' spacing={2}>
                        <Typography
                            variant='h6'
                            component='h6'
                            fontWeight={600}
                        >
                            Qualifications
                        </Typography>
                        <Stack alignItems='flex-start' spacing={1}>
                            {Achievements.Qualifications.map(qualification => (
                                <Stack
                                    key={qualification}
                                    direction='row'
                                    alignItems='center'
                                >
                                    <Typography
                                        variant='caption'
                                        component='li'
                                    ></Typography>
                                    <Typography variant='caption'>
                                        {qualification}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Stack alignItems='flex-start' spacing={2}>
                        <Typography
                            variant='h6'
                            component='h6'
                            fontWeight={600}
                        >
                            Further Training
                        </Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                            <Stack spacing={1}>
                                {Achievements.FurtherTraining.slice(0, 3).map(
                                    training => (
                                        <Stack
                                            key={training}
                                            direction='row'
                                            alignItems='center'
                                        >
                                            <Typography
                                                variant='caption'
                                                component='li'
                                            ></Typography>
                                            <Typography variant='caption'>
                                                {training}
                                            </Typography>
                                        </Stack>
                                    )
                                )}
                            </Stack>
                            <Stack spacing={1}>
                                {Achievements.FurtherTraining.slice(3, 6).map(
                                    training => (
                                        <Stack
                                            key={training}
                                            direction='row'
                                            alignItems='center'
                                        >
                                            <Typography
                                                variant='caption'
                                                component='li'
                                            ></Typography>
                                            <Typography variant='caption'>
                                                {training}
                                            </Typography>
                                        </Stack>
                                    )
                                )}
                            </Stack>
                            <Stack spacing={1}>
                                {Achievements.FurtherTraining.slice(6, 9).map(
                                    training => (
                                        <Stack
                                            key={training}
                                            direction='row'
                                            alignItems='center'
                                        >
                                            <Typography
                                                variant='caption'
                                                component='li'
                                            ></Typography>
                                            <Typography variant='caption'>
                                                {training}
                                            </Typography>
                                        </Stack>
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}
export default memo(Qualifications);
