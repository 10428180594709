import { ReactElement, ReactNode, ElementType, memo } from "react";
import ActionCard, { WithCardProps } from "./Action.Card";
import ActionButton, { WithButtonProps } from "./Action.Button";
import ActionImage, { WithImageProps } from "./Action.Image";
import ActionIconButton, { WithIconButtonProps } from "./Action.IconButton";
import Grid, { GridProps } from "@mui/material/Grid";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { WithLink } from "../../../config";

export type WithActionProps<T = {}> = WithLink<
  WithGrid<
    T & {
      component?: ElementType<any>;
      label?: ReactNode;
      GridProps?: GridProps;
      loading?: boolean;
      loadingColor?: Exclude<ColorOptions, "default">;
      disabled?: boolean;
      CircularProgressProps?: CircularProgressProps;
    }
  >
>;

export type ActionTypes = { GridProps?: GridProps } & (
  | WithButtonProps<{ av: "Button" }>
  | WithIconButtonProps<{ av: "IconButton" }>
  | WithCardProps<{ av: "Card" }>
  | WithImageProps<{ av: "Image" }>
);
function Action(props: ActionTypes): ReactElement {
  return props.grid ? (
    <Grid
      item
      xs={props.xs ? props.xs : 12}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      {...props.GridProps}
    >
      <ActionItem {...props} />
    </Grid>
  ) : (
    <ActionItem {...props} />
  );
}
function ActionItem(props: ActionTypes): ReactElement {
  return props.av === "Card" ? (
    <ActionCard {...props} />
  ) : props.av === "Button" ? (
    <ActionButton {...props} />
  ) : props.av === "IconButton" ? (
    <ActionIconButton {...props} />
  ) : props.av === "Image" ? (
    <ActionImage {...props} />
  ) : (
    <></>
  );
}

export type {
  WithButtonProps,
  WithIconButtonProps,
  WithCardProps,
  WithImageProps,
};
export { ActionButton, ActionIconButton, ActionCard, ActionImage };
export default memo(Action) as typeof Action;