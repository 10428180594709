import {ReactElement, memo} from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Profile from "../../assets/images/ProfileHeader.jpeg";
import LogoName from "../../assets/images/Logo_Name.png";

function Header(): ReactElement {
  return (
    <Stack
      component="header"
      justifyContent="space-between"
      direction={{xs: "column", md: "row"}}
      alignItems="center"
      spacing={5}
      py={3}
    >
      <Stack component="aside" flex={1}>
        <img src={LogoName} alt={LogoName} style={{width: "100%"}}/>
        <Stack width="fit-content" alignItems="center">
          <Typography component="q" mt={5} textAlign="center">
            The purpose of our journey is to restore ourselves to wholeness.
          </Typography>
          <Typography textAlign="center">-Debbi Ford</Typography>
        </Stack>
      </Stack>
      <Stack component="aside" flex={1}>
        <img src={Profile} alt={Profile} style={{width: "100%"}}/>
      </Stack>
    </Stack>
  );
}

export default memo(Header);
