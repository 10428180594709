import { ReactElement, ReactNode, memo } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import Typography, { TypographyProps } from "@mui/material/Typography";
import useNavigate from "../../../hooks/useNavigate";
import { SxType } from "../..";
import { WithActionProps } from ".";
import { WithLink } from "../../../config";

export type WithImageProps<T = {}> = WithActionProps<
  T &
    BoxProps & {
      src: string;
      disabled?: boolean;
      disableRipple?: boolean;
      disableTouchRipple?: boolean;
      ButtonProps?: ButtonBaseProps;
      sxButton?: SxType;
      overlayText?: ReactNode;
      overlayTextProps?: TypographyProps;
    }
>;

function ActionImage(props: WithLink<WithImageProps>): ReactElement {
  const { navigateOnClick } = useNavigate();
  return (
    <ButtonBase
      LinkComponent="a"
      disableRipple={props.disableRipple}
      disableTouchRipple={props.disableTouchRipple}
      disabled={props.disabled}
      href={props.href ?? ""}
      target={props.target}
      sx={{
        position: "relative",
        overflow: "hidden",
        zIndex: 0,
        ...props.sxButton,
      }}
    >
      <Typography
        color="#efefef"
        position="absolute"
        top="50%"
        left="50%"
        zIndex={10}
        sx={{ transform: "translate(-50%,-50%)" }}
        {...props.overlayTextProps}
      >
        {props.overlayText}
      </Typography>
      <Box
        component="img"
        alt={props.src}
        width="100%"
        draggable={false}
        sx={{
          objectFit: "contain",
          objectPosition: "center",
          filter: props.overlayText ? "brightness(25%)" : "none",
        }}
        {...props}
        onClick={
          props.to ? navigateOnClick(props.to, props.onClick) : props.onClick
        }
      />
    </ButtonBase>
  );
}

export default memo(ActionImage);