import { ReactElement, ChangeEvent, memo } from 'react';
import Grid from '@mui/material/Grid';
import {
    WithSwitchProps,
    WithCheckboxProps,
    SwitchField,
    CheckboxField
} from './Boolean.Field';
import RadioField, { WithRadioProps } from './Radio.Field';
import TextField, { WithTextFieldProps } from './TextField.Field';

export interface IFieldOption {
    value: string;
    label?: string;
}
export type InputEventProps = ChangeEvent<HTMLInputElement>;
export interface BaseFieldProps extends WithGrid {
    name: string;
    label?: string;
    value?: any;
    disabled?: boolean;
    size?: 'small' | 'medium';
    color?: Exclude<ColorOptions, 'inherit'>;
    fieldVariant?: 'standard' | 'outlined' | 'filled';
    optional?: boolean | undefined;
}

export type FieldOptions =
    | WithTextFieldProps<{ variant?: 'textfield' }>
    | WithRadioProps<{ variant: 'radio' }>
    | WithSwitchProps<{ variant: 'switch' }>
    | WithCheckboxProps<{ variant: 'checkbox' }>;

function Field(props: FieldOptions) {
    return (
        <FieldContainer {...props}>
            {props.variant === 'switch' ? (
                <SwitchField color={props.color} {...props} />
            ) : props.variant === 'checkbox' ? (
                <CheckboxField color={props.color} {...props} />
            ) : props.variant === 'radio' ? (
                <RadioField color={props.color} {...props} />
            ) : (
                <TextField
                    color={props.color}
                    {...props}
                    variant={props.fieldVariant}
                />
            )}
        </FieldContainer>
    );
}

function FieldContainer(props: WithChildren<WithGrid>): ReactElement {
    return props.grid ? (
        <Grid item {...props} xs={props.xs ?? 12}>
            {props.children}
        </Grid>
    ) : (
        <>{props.children}</>
    );
}

export default memo(Field) as typeof Field;
