import { ReactElement, ChangeEvent, memo } from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import { BaseFieldProps } from './Field';

export type WithSwitchProps<T = {}> = BaseFieldProps &
    T & {
        onChange?: (
            event: ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) => void;
        labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    };

function Switch({
    color,
    size,
    disabled,
    onChange,
    value,
    label,
    name,
    labelPlacement,
    optional
}: WithSwitchProps): ReactElement {
    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    color={color}
                    size={size}
                    disabled={disabled}
                    onChange={onChange}
                    checked={value === true}
                    required={!optional}
                />
            }
            label={label ?? name}
            labelPlacement={labelPlacement}
        />
    );
}

export type WithCheckboxProps<T = {}> = T &
    WithSwitchProps<{
        CheckboxProps?: CheckboxProps;
    }>;

function Checkbox({
    color,
    size,
    disabled,
    onChange,
    value,
    label,
    name,
    labelPlacement,
    optional,
    CheckboxProps
}: WithCheckboxProps): ReactElement {
    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    color={color}
                    size={size}
                    disabled={disabled}
                    onChange={onChange}
                    checked={value === true}
                    required={!optional}
                    {...CheckboxProps}
                />
            }
            label={label ?? name}
            labelPlacement={labelPlacement}
        />
    );
}

const SwitchField = memo(Switch);
const CheckboxField = memo(Checkbox);
export { SwitchField, CheckboxField };
