import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';

export default function NotFoundPage(): ReactElement {
    return (
        <div>
            <Typography component='h1' variant='h1'>
                Page Not Found
            </Typography>
        </div>
    );
}
