import {ReactElement, memo} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {About, ILink, Navbar, Qualifications} from "../components";

function AboutPage(): ReactElement {
  const links: Array<ILink> = [
    {label: "Home", to: "/"},
    {label: "Services", to: "/Services"},
    {label: "Fees", to: "/Fees"},
    {label: "Contact", href: "#contact"},
  ];
  return (
    <Navbar links={links}>
      <Container component="main" maxWidth="lg" sx={{mt: {xs: 3, md: 18}}}>
        <About page/>
        <Typography mt={2}>
          I accomplished both my undergraduate and post-graduate degrees at the
          University of Pretoria. My masters’ degree in clinical psychology was
          predominantly psychodynamic in nature and allowed for the practical
          application of theoretical knowledge in various therapeutic contexts.
          I completed my internship at Weskoppies Psychiatric Hospital where I
          worked within a cohesive multi-disciplinary team. My community service
          year was at Grootvlei Correctional Centre where I provided
          psychotherapy to a large forensic population. I have gained clinical
          experience with in-patients and out-patients in community,
          psychiatric, forensic, rehabilitation, and hospital contexts.
          Currently, I have a private practice and work at various hospitals.
          <br/>
          <br/>
          Therapeutically, I utilise an integrative interactional approach which
          incorporates person-centred and systems theory paradigms along with a
          psychodynamic background. I have found that the flexibility of such an
          approach allows for a better theoretical understanding of the unique
          needs of each individual to facilitate effective psychotherapeutic
          processes.
          <br/>
          I aim to provide interactional therapy that is empathetic,
          non-judgemental and provides unconditional positive regard to the
          individual within a safe space. My hope is that every person embarks
          on a therapeutic process that enables them to gain clarity of their
          own and others' interactional styles to become more flexible,
          congruent, and able to effectively express personal needs within their
          lives.
          <br/>
          <br/>
          “I relate, therefore I am. Thus, how I impact on you and how you
          impact on me determines the nature and quality of our being.” <br/> –
          Charl Vorster
        </Typography>
      </Container>
      <Qualifications/>
    </Navbar>
  );
}

export default memo(AboutPage);
