import { ReactElement, memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function ContactDetails(): ReactElement {
  return (
    <Stack spacing={2}>
      <ContactDetail
        label="Phone"
        value="082 307 7762"
        href="tel:+27823077762"
      />
      <ContactDetail
        label="Email"
        value="psych@deidredutoit.co.za"
        href="mailto:psych@deidredutoit.co.za"
      />
      <Stack marginY="1rem" spacing={0}>
        <Typography fontWeight={600} variant="subtitle2" component="label">
          Address:
        </Typography>
        <Typography
          variant="subtitle2"
          component="a"
          href="https://www.google.com/maps/place/26+Innes+Ln,+Waverley,+Bloemfontein,+9301/data=!4m2!3m1!1s0x1e8fcffce1d34249:0x77547b91cc678042?sa=X&ved=2ahUKEwiKr--4vJz0AhXUmFwKHYi6DuEQ8gF6BAgIEAE"
          target="_blank"
        >
          26 Innes Avenue
          <br />
          Waverley
          <br />
          Bloemfontein
        </Typography>
      </Stack>
    </Stack>
  );
}

interface ContactDetailProps {
  label: string;
  value: string;
  href?: string;
}

function ContactDetail({
  label,
  value,
  href,
}: ContactDetailProps): ReactElement {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        fontWeight={600}
        variant="body2"
        component="caption"
        marginRight="0.4rem"
      >
        {label}:
      </Typography>
      <Typography
        variant="body2"
        component={href ? "a" : "span"}
        href={href}
        target={href && "_blank"}
        rel="noopener noreferrer"
        color="inherit"
      >
        {value}
      </Typography>
    </Stack>
  );
}
export default memo(ContactDetails);