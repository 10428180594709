import { ChangeEvent, useCallback, useState } from 'react';

export default function useInput(
    initialState?: string | null | undefined
): [
    input: string | null | undefined,
    updateInput: (e: ChangeEvent<HTMLInputElement>) => void
] {
    const [input, setInput] = useState(initialState);
    const updateInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value),
        []
    );
    return [input, updateInput];
}
