import { AxiosRequestConfig } from 'axios';

export const GET = [
    '/blogs/all',
    '/blogs',
    '/sponsors',
    '/sponsors/contributors'
] as const;
export const POST = [
    '/blogs/create',
    '/blogs/image',
    '/contact',
    '/contact/donate',
    '/contact/donate-money',
    '/sponsors/add',
    '/sponsors/contributor/add',
    '/users/checkSession',
    '/users/login',
    '/users/logout'
] as const;
export const PUT = ['/blogs/edit'] as const;
export const DELETE = [] as const;
export const ROUTES = { GET, POST, PUT, DELETE };
export interface IDataResponse<GenericData extends unknown = void> {
    result: 'catch error' | 'error' | 'success';
    data: GenericData;
    programmerEndMessage?: string;
    frontEndMessage?: string;
}

export type fetchHandlerType<FetchGeneric extends unknown = void> = (
    opt?: {
        q?: string;
        ext?: string;
        message?: string | boolean;
    } & AxiosRequestConfig<any>,
    callback?: (
        response: IDataResponse<FetchGeneric>,
        message?: string | boolean
    ) => void
) => Promise<IDataResponse<FetchGeneric>>;

export const catchResponse: <CatchGeneric>(
    err: any | unknown
) => IDataResponse<CatchGeneric> = err => ({
    result: 'catch error',
    data: err,
    frontEndMessage: 'There was a catch error. Contact admin.vermelion.net',
    programmerEndMessage: 'Catch Error'
});
