import {ReactElement, memo} from "react";
import Container from "@mui/material/Container";
import {ILink, Navbar, Services} from "../components";
import {ServicesConstant} from "../constants";

function ServicesPage(): ReactElement {
  const services = [
    ServicesConstant.Adult,
    ServicesConstant.Child,
    ServicesConstant.Couples,
    ServicesConstant.Family,
    ServicesConstant.Horse,
    ServicesConstant.Group,
    ServicesConstant.Assessments,
  ];

  const links: Array<ILink> = [
    {label: "Home", to: "/"},
    {label: "About", to: "/About"},
    {label: "Fees", to: "/About#Fees"},
  ];

  return (
    <Navbar links={links}>
      <Container component="main" maxWidth="lg" sx={{mt: {sm: -15, md: -5}}}>
        <Services services={services}/>
      </Container>
    </Navbar>
  );
}

export default memo(ServicesPage);
