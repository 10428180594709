import { ReactElement, memo } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ContactForm from './Contact.Form';

function Contact(): ReactElement {
    return (
        <Container component='section' maxWidth='lg' id='contact'>
            <Typography component='h1' variant='h1'>
                Contact Me
            </Typography>
            <ContactForm />
        </Container>
    );
}
export default memo(Contact);
