import { ReactElement, ChangeEvent, memo } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { BaseFieldProps, IFieldOption } from './Field';

export type WithRadioProps<T = {}> = BaseFieldProps &
    T & {
        onChange?: (
            event: ChangeEvent<HTMLInputElement>,
            value: string
        ) => void;
        labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
        options?: Array<IFieldOption>;
    };

function RadioField({
    name,
    label,
    value,
    color,
    options,
    labelPlacement
}: WithRadioProps): ReactElement {
    return (
        <FormControl component='fieldset'>
            <FormLabel component='legend'>{label ?? name}</FormLabel>
            <RadioGroup
                aria-label={name ?? 'radio group'}
                name={`${label ?? name}-radio-buttons'`}
                value={value}
                onChange={() => {}}
                color={color}
            >
                {options?.map(option => (
                    <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.label ?? option.value}
                        labelPlacement={labelPlacement}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default memo(RadioField);
