import {ReactElement, memo} from "react";
import Grid from "@mui/material/Grid";
import AssessmentsIcon from "../../assets/images/services/check-list.png";
import CoupleIcon from "../../assets/images/services/couple.png";
import FamilyIcon from "../../assets/images/services/family.png";
import GroupIcon from "../../assets/images/services/people.png";
import IndividualIcon from "../../assets/images/services/user.png";
import HorseIcon from "../../assets/images/services/horse-head.png";
import Fee from "./Fee";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

function Fees(): ReactElement {
  return (
    <Stack my="4rem">
      <Typography variant="h3" component="h3" fontWeight={600} mb={4}>
        Fees
      </Typography>
      <Grid container columnSpacing={4} rowSpacing={7}>
        <Grid item xs={12} sm={6} md={4}>
          <Fee
            icon={IndividualIcon}
            label="Individual"
            value="Learn more"
            to="/fees"
            action
          /></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Fee
            icon={CoupleIcon}
            label="Couples"
            value="R1250 / session (50-55min)"
          /></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Fee
            icon={FamilyIcon}
            label="Family"
            value="R1450 / session (50-55min)"
          /></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Fee
            icon={GroupIcon}
            label="Group Therapy"
            value="Contact Me"
            href="#contact"
            action
          /></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Fee
            icon={AssessmentsIcon}
            label="Assessments"
            value="Contact Me"
            href="#contact"
            action
          /></Grid> <Grid item xs={12} sm={6} md={4}>
        <Fee
          icon={HorseIcon}
          label="Equine Assisted Psychotherapy (EAP)"
          value="Contact Me"
          href="#contact"
          action
        /></Grid>
      </Grid>
      <Typography mt={2}>*Subject to annual increases</Typography>
    </Stack>
  );
}

export default memo(Fees);
