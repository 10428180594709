import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { Theme } from "./config";
import Routes from "./Routes";
import { Providers } from "./context";
import { useDimensions } from "./hooks";

const App: React.FC = () => {
  const { width } = useDimensions();
  const muiTheme = createTheme({
    palette: {
      primary: { main: Theme.primary.base },
      secondary: { main: Theme.secondary.base },
      mode: "light",
    },
    typography: {
      fontFamily: "'Lato', sans-serif;",
      h3: {
        fontSize: 30,
        "@media (min-width:600px)": {
          fontSize: 38,
        },
        "@media (min-width:1000px)": {
          fontSize: 48,
        },
      },
    },
  });
  return (
    <StyledThemeProvider theme={Theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Providers>
          <Routes />
        </Providers>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default App;