import {ReactElement, memo} from "react";
import Container from "@mui/material/Container";
import {ServicesConstant} from "../constants";
import {
  About,
  Services,
  Header,
  Navbar,
  Qualifications,
  Fees,
  ILink,
  PopiSection,
} from "../components";

function HomePage(): ReactElement {
  const services = [
    ServicesConstant.Adult,
    ServicesConstant.Couples,
    ServicesConstant.Group,
    ServicesConstant.Child,
    ServicesConstant.Horse,
    ServicesConstant.Assessments,
  ];

  const links: Array<ILink> = [
    {label: "Services", href: "#services"},
    {label: "About", href: "#about"},
    {label: "Fees", href: "#fees"},
    {label: "Contact", href: "#contact"},
  ];

  return (
    <Navbar links={links}>
      <Container component="main" maxWidth="lg">
        <Header/>
        <Services services={services} home/>
        <About/>
      </Container>
      <Qualifications/>
      <Container component="main" maxWidth="lg" id="fees">
        <Fees/>
      </Container>
      <Container component="main" maxWidth="lg" id="fees">
        <PopiSection/>
      </Container>
    </Navbar>
  );
}

export default memo(HomePage);
