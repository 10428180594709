import { ReactElement } from "react";
import Pages from "../pages";

export const publicRoutes = ["/", "/about", "/services", "/fees"] as const;

export type routeTypes = typeof publicRoutes[number];

export interface INavigate {
  path: routeTypes;
  ext?: string | undefined;
}

export type WithLink<T = {}> = T & {
  to?: INavigate;
  href?: string | undefined;
  blank?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top" | (string & {});
};

interface IRoutes {
  path: routeTypes;
  element: ReactElement;
}

const routes: {
  public: IRoutes[];
} = {
  public: [
    {
      path: "/",
      element: <Pages.Home />,
    },
    {
      path: "/about",
      element: <Pages.About />,
    },
    {
      path: "/services",
      element: <Pages.Services />,
    },
    {
      path: "/fees",
      element: <Pages.Fees />,
    },
  ],
};

export default routes;